import React from "react"
import { Link as SLink } from "react-scroll"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Reveal from "react-reveal/Reveal"
import SeoTitle from "../components/Title/SeoTitle"
import Fade from "react-reveal/Fade"
import PreRegister from "../components/Preregsiter"
import "../assets/termynal.css"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          urls {
            cliDownload
            register
          }
        }
      }

      webhooks: file(relativePath: { eq: "webhooks_table.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <section className="seo_home_area">
        <div className="home_bubble">
          <div className="bubble b_one"></div>
          <div className="bubble b_two"></div>
          <div className="bubble b_three"></div>
          <div className="bubble b_four"></div>
          <div className="bubble b_five"></div>
          <div className="bubble b_six"></div>
          <div className="triangle b_seven" data-parallax='{"x": 20, "y": 150}'>
            <img src={require("../images/seo/triangle_one.png")} alt="" />
          </div>
          <div
            className="triangle b_eight"
            data-parallax='{"x": 120, "y": -10}'
          >
            <img src={require("../images/seo/triangle_two.png")} alt="" />
          </div>
          <div className="triangle b_nine">
            <img src={require("../images/seo/triangle_three.png")} alt="" />
          </div>
        </div>
        <div className="banner_top">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center seo_banner_content">
                <Reveal effect="fadeInUp" duration={500}>
                  <h1>Webhooks for developers</h1>
                </Reveal>
                <Reveal effect="fadeInUp" duration={1000}>
                  <p className="wow fadeInUp" data-wow-delay="0.5s">
                    Focus on building features instead of integrations.
                  </p>
                </Reveal>
                <a
                  href={data.site.siteMetadata.urls.cliDownload}
                  className="seo_btn seo_btn_one btn_hover wow fadeInLeft"
                >
                  Download CLI
                </a>
                <SLink
                  href="#"
                  to="learnmore"
                  className="seo_btn seo_btn_two btn_hover wow fadeInRight"
                  spy={true}
                  smooth={true}
                  offset={-100}
                >
                  Learn More
                </SLink>
              </div>
            </div>
            <div className="saas_home_img d-flex justify-content-center align-items-center">
              <Reveal effect="fadeInUp" duration={1400}>
                <div
                  id="termynal"
                  data-termynal
                  className="text-left shadow"
                  style={{ marginBottom: "-140px" }}
                >
                  <span data-ty="input">
                    hkat login --email mary@hookactions.com
                  </span>
                  <span data-ty>Successfully logged in</span>
                  <span data-ty="input">
                    hkat salesforce trigger AFTER_INSERT --sobject Account
                  </span>
                  <span data-ty>Event sent</span>
                  <span data-ty="input">
                    hkat calendly trigger invitee.created
                  </span>
                  <span data-ty>Event sent</span>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_service_area sec_pad">
        <div className="container" id="learnmore">
          <SeoTitle
            Title="Modern Webhook Management"
            TitleP="The single source of truth for your webhooks."
          />
          <Img
            fluid={data.webhooks.childImageSharp.fluid}
            className="shadow"
            alt="webhooks"
          />
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="seo_service_info">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-6">
                <div className="seo_features_img">
                  <div className="round_circle"></div>
                  <div className="round_circle two"></div>
                  <img src={require("../images/seo/features_img.png")} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <Fade bottom cascade>
                  <div className="seo_features_content">
                    <h2>Confidently ship to production</h2>
                    <p>
                      Test your webhooks locally with the HookAction CLI. Stop
                      relying on poorly written and outdated third-party
                      documentation.
                    </p>
                    <div className="media seo_features_item">
                      <div className="icon">
                        <img
                          src={require("../images/seo/icon2.png")}
                          alt=""
                          style={{ maxWidth: "50%" }}
                        />
                      </div>
                      <div className="media-body">
                        <h3>Fast</h3>
                        <p>
                          Forget about reading third-party documentation and
                          learning how their API works. Integrate simply with
                          the HookActions website.
                        </p>
                      </div>
                    </div>
                    <div className="media seo_features_item">
                      <div className="icon two">
                        <img src={require("../images/seo/icon3.png")} alt="" />
                      </div>
                      <div className="media-body">
                        <h3>Easy tooling</h3>
                        <p>
                          Test integrations offline using the HookActions CLI.
                          Send real events to your local server.
                        </p>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="seo_features_one sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="seo_features_img seo_features_img_two">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img
                  src={require("../images/seo/features_img_two.png")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2 className="wow fadeInUp">
                    Developing and managing webhooks is time-consuming and
                    monotonous
                  </h2>
                  <h6 className="wow fadeInUp">
                    Focus on developing new features, not integrating webhooks.
                  </h6>
                  <p className="wow fadeInUp">
                    Our singular focus is managing webhooks so you don't have
                    to. Remove your hundreds of bookmarks for all the
                    third-party APIs and documentation. Use HookActions and
                    never misplace a webhook again.
                  </p>
                  <a
                    href={data.site.siteMetadata.urls.register}
                    className="seo_btn seo_btn_one btn_hover wow fadeInUp"
                  >
                    Get Started
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <PreRegister />
    </Layout>
  )
}

export default IndexPage
